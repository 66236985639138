<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">Süreç Rapor</div>
    <div></div>

    <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading"
      :element-loading-text='loading ? $t("src.views.apps.rapor.surecRaporLoading") : ""'
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end; align-items: flex-end"
        v-if="isDesktop">
        <div class="display-end">
          <ul class="islemler">
            <el-button type="text" v-on:click="getSurecRapor()"
              style="font-family: 'Poppins', sans-serif; font-size: 14px;">
              <span style="color: #4a596a">
                Raporu indirmek için tıklayınız.
              </span>
            </el-button>
          </ul> &nbsp;
          <el-tooltip :content="'Süreç Rapor İndir'" :open-delay="500">
            <el-button type="text" style="color: #ee1010" v-on:click="getSurecRapor()" class="pdf-button">
              <i class="mdi mdi-download mdi-24px"></i>
            </el-button>
          </el-tooltip>
        </div>
      </el-col>

      <el-col :lg="24" :md="24" :sm="24" :xs="24"
        style="display: flex; justify-content: flex-start; align-items: flex-end" v-else>
        <div class="display-end-sol" style="display: flex; align-items: center">
          <el-button type="text" style="color: #ee1010" v-on:click="getSurecRapor()" class="pdf-button">
            <i class="mdi mdi-download mdi-24px"></i>
          </el-button>
          <ul class="islemler">
            <el-button type="text" v-on:click="getSurecRapor()"
              style="font-family: 'Poppins', sans-serif; font-size: 14px;">
              <span style="color: #4a596a">
                Raporu indirmek için tıklayınız.
              </span>
            </el-button>
          </ul>
        </div>
      </el-col>


      <el-table :data="sozlesmeProjeList" style="width: 100%" border id="surecrapor">
        <el-table-column :fixed="isDesktop ? 'left' : null" prop="sozlesmeBaslik" label="Sözleşme" width="300">
          <template slot="header">
            <div class="pl-5" style="color: #00385a">Sözleşme</div>
          </template>

          <template slot-scope="prop">
            <div :style="{
              display: 'flex',
              alignItems: 'center'
            }" class="pl-5 new-line-text">
              {{ prop.row.sozlesmeBaslik }}
            </div>
          </template>
        </el-table-column>

        <el-table-column v-for="(item, index) in tarihler2" :prop="item.prop" :key="index" align="center" width="200">
          <template slot="header">
            <!-- color: '#575656', background: `${item.color}` -->
            <div class="new-line-text">
              <div v-if="shouldDisplayYear(index)"
                style="height: 25px; display: flex; justify-content: center; align-items: center; font-size: 14px!important;">
                {{ item.year }}
              </div>
              <div
                style="height: 25px; display: flex; justify-content: center; align-items: center; font-size: 14px!important;">
                {{ item.month }}
              </div>
            </div>
          </template>

          <template slot-scope="prop">
            <div style="padding: 10px">
              <div class="projeRow new-line-text" v-for="(proje, i) in checkMonths(prop.row, item)" :key="i">
                <span>{{ proje.projeBaslik }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>




<script>
import EventBus from "@/components/event-bus";
import notification from "../../../notification";
import functions from "../../../functions";
import wsProvider from "../../../WSProvider/provider/WSProvider";
import projeService from "../../../WSProvider/ProjeService";
import sozlesmeService from "../../../WSProvider/SozlesmeService";

var moment = require("moment");

export default {
  name: "SurecRapor",
  data() {
    return {
      displayedYears: new Set(),
      isDesktop: false,
      loading: false,
      projeList: [],
      sozlesmeProjeList: [],
      rapor: "",
      selectIcon: "1",
      sizes: 10,
      page: 0,
      postPage: 0,
      total: 4,
      tarihler: [],
      tarihler2: [],
    };
  },

  created() {
    this.checkDevice();
  },

  mounted() {
    this.loading = true;
    //this.getProjeListesi();
    //this.getSozlesmeListesi();
    this.getSozlesmeListesiProje();
    this.tarihler = functions.daysInMonths();

    this.sozlesmeProjeList.forEach((sozlesme) => {
      sozlesme.sozlesmeProjeleri.forEach((proje) => {
        proje.sozlesmeAylari = getMonthsBetween(proje.baslamaTarihi, proje.isTeslimTarihi);
      });
    });

    window.addEventListener('resize', this.checkDevice);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },

  methods: {
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    shouldDisplayYear(index) {
      const year = this.tarihler2[index].year;

      if (!this.displayedYears.has(year)) {
        this.displayedYears.add(year);
        return true;
      }


    },

    /*
    pdf() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeListesiProje().then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.sozlesmeSayisi);
              response.data.forEach((el) => {
                /*el.dogumSaati = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('hh:mm:ss') : ''
                                el.dogumGunu = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('YYYY-MM-DD') : ''
                                el.kayitSaati = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('hh:mm:ss') : ''
                                el.kayitGunu = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('YYYY-MM-DD') : ''
              });
              this.sozlesmeProjeList = response.data;
              this.total = parseInt(response.count);

              this.sozlesmeProjeList.forEach((sozlesme) => {
                sozlesme.sozlesmeProjeleri.forEach((proje) => {
                  proje.sozlesmeAylari = getMonthsBetween(proje.baslamaTarihi, proje.isTeslimTarihi);
                });
              });

              console.log("***************");
              console.log(this.sozlesmeProjeList);

              

              try {
                var aylaraGoreProjeler = [];
                for (var i = 1; i <= 12; i++) {
                  aylaraGoreProjeler.push({ month: i, projeler: "" });
                }
                this.sozlesmeProjeList.forEach((sozlesme) => {
                  var array = [];
                  var item = {
                    rowspan: sozlesme.sozlesmeProjeleri.length - 1,
                    text: sozlesme.sozlesmeBaslik,
                  };
                  array.push(item);
                  sozlesme.sozlesmeProjeleri.forEach((proje) => {
                    proje.sozlesmeAylari.forEach((e) => {
                      var ay = aylaraGoreProjeler.find(
                        (a) => a.month == e.monthIndex);
                      if (ay) ay.projeler = proje.projeBaslik;
                    });
                  });
                  aylaraGoreProjeler.forEach((el) => {
                    var str = {};
                    el.projeler.length == 0 ? (str = { text: "" }) : (str = { text: el.projeler });
                    str._minWidth = 50;
                    array.push(str);
                  });
                  console.log({ array });
                  dd.content[3].table.body.push(array);

                  aylaraGoreProjeler.forEach((i) => {
                    i.projeler = "";
                  });
                });
                //console.log(dd.content[3].table.body)
              } catch (e) {
                console.log(e);
              }
              /*
            this.sozlesmeProjeList.forEach((sozlesme) => {
                var proje = {rowspan: projeler.length, text: sozlesme.sozlesmeBaslik}
                array.push(proje)

                aylaraGoreProjeler.forEach(projeler => {})
            })

              pdfMake.createPdf(dd).download("Deneme.pdf");
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },*/
    /*
        checkMonths(sozlesme, month) {
          let result = [];
          this.sozlesmeProjeList.forEach((sozlesme) => {
            sozlesme.sozlesmeProjeleri.forEach((proje) => {
              proje.sozlesmeAylari = getMonthsBetween(proje.baslamaTarihi, proje.isTeslimTarihi);
            });
          });
    
          sozlesme.sozlesmeProjeleri.forEach((proje) => {
            //console.log(sozlesme.sozlesmeBaslik)
            //console.log(proje.projeBaslik)
            proje.sozlesmeAylari.forEach((ay) => {
              //ay ilkini alıyo
              ay.monthIndex == month.monthIndex ? result.push(proje) : "";
              //console.log(ay.monthIndex)
              //console.log("------------------------------------------")
              //console.log(proje.projeBaslik)
              //console.log()
            });
          });
          //console.log(result)
          return result;
        },*/


    checkMonths(sozlesme, month) {
      const result = [];
      const sozlesmeProjeleri = new Set();

      for (const proje of sozlesme.sozlesmeProjeleri) {
        proje.sozlesmeAylari = getMonthsBetween(proje.baslamaTarihi, proje.isTeslimTarihi);

        for (const ay of proje.sozlesmeAylari) {
          if (ay.monthIndex === month.monthIndex && !sozlesmeProjeleri.has(proje)) {
            result.push(proje);
            sozlesmeProjeleri.add(proje);
            break;
          }
        }
      }

      return result;
    },


    getProjeSayisi() {
      try {
        projeService
          .projeSayisi(this.selectIcon)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
            }
            this.total = parseInt(response.data);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.total = 0;
              }
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },
    /*
            getProjeListesi() {
                var bugunTarih = new Date();
                try{
                    this.loading = true;
                    projeService.projeListesi(this.selectIcon,this.postPage, this.sizes).then((response) => {
                        localStorage.setItem("userDataBGSurec", response.token)
                        if (response.status == 200) {
                            this.total = parseInt(response.data.projeSayisi);
                            try{
                                response.data.forEach(proje => {
                                    proje.bitenyuzdedeger = 0;
                                     proje.sozlesmeler.forEach(sozlesme => {
                                        sozlesme.baslamaTarihi = new Date(sozlesme.baslamaTarihi)
                                        sozlesme.isTeslimTarihi = new Date(sozlesme.isTeslimTarihi)
                                        sozlesme.sozlesmebittimi = this.gunFarki(sozlesme.isTeslimTarihi);
                                        proje.bitenyuzdedeger += sozlesme.sozlesmebittimi == 1 ? parseInt(sozlesme.yuzdeDeger) : 0

                                        if(new Date(sozlesme.baslamaTarihi).getTime() < bugunTarih.getTime() && new Date(sozlesme.isTeslimTarihi).getTime() < bugunTarih.getTime()){
                                            sozlesme.bitis = 2
                                         }
                                        else if(new Date(sozlesme.baslamaTarihi).getTime() < bugunTarih.getTime() && new Date(sozlesme.isTeslimTarihi).getTime() > bugunTarih.getTime()){
                                            sozlesme.bitis = 1
                                        }
                                        else {
                                            sozlesme.bitis = 0
                                        }
                                    }) 
                                })
                            }catch(e){
                                console.log("e  =>  ", e)
                            }
                            console.log(" response =>    ",response.data)
                            this.projeList = response.data;
                            this.total = parseInt(response.count);
                            console.log("PROJE RAPOR")
                            console.log(this.projeList)
                        } 
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.projeList = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    // notification.Status("success", this, "An error occurred while fetching the customer list.")
                    this.loading = false
                    console.log(e)
                }
            },*/
    /*         
    getSozlesmeListesi() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeListesi(this.selectIcon, this.postPage, this.sizes).then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.sozlesmeSayisi);
              
              this.sozlesmeList = response.data;
              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },*/

    getSozlesmeListesiProje() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeListesiProje().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token);
          if (response.status == 200) {
            this.total = parseInt(response.data.sozlesmeSayisi);
            this.sozlesmeProjeList = response.data;
            this.total = parseInt(response.count);
            this.sozlesmeProjeList.forEach(projeler => {

              projeler.sozlesmeProjeleri.forEach(proje => {
                proje.toplamProjeSuresi = 0
                proje.baslamaGecenSure = 0
                proje.baslamaSureYuzde = 0
                proje.kalanSure = 0

                proje.toplamProjeSuresi = getDays(proje.baslamaTarihi, proje.tamamlanmaTarihi);
                projeBasladiMi(moment().format("YYYY-MM-DD"), proje.baslamaTarihi) ? proje.baslamaGecenSure = getDays(proje.baslamaTarihi, moment().format("YYYY-MM-DD")) : proje.baslamaGecenSure = 0
                proje.kalanSure = proje.toplamProjeSuresi - proje.baslamaGecenSure
                if (proje.kalanSure < 0) {
                  proje.kalanSure = 0
                }

                proje.baslamaSureYuzde = parseFloat(parseInt(proje.baslamaGecenSure) * 100 / parseInt(proje.toplamProjeSuresi)).toFixed(1)
                if (proje.baslamaSureYuzde > 100) {
                  proje.baslamaSureYuzde = 100
                }

                proje.toplamSozlesme = 0
                proje.bitenSozlesme = 0

                proje.toplamSozlesme += parseFloat(proje.yuzdeDeger)
                proje.bitenSozlesme += sozlesmeBittiMi(moment().format("YYYY-MM-DD"), proje.tamamlanmaTarihi) ? parseFloat(proje.yuzdeDeger) : 0

                proje.bitmeYuzdesi = parseFloat(proje.bitenSozlesme * 100 / proje.toplamSozlesme).toFixed(1)
                proje.toplamYuzde = parseFloat((proje.yuzdeDeger) * 100 / proje.toplamSozlesme).toFixed(1)
                proje.toplamCalisilanGun = 0

                proje.sozlesmeAylari = getMonthsBetween(proje.baslamaTarihi, proje.tamamlanmaTarihi)

                proje.sozlesmeAylari.forEach(ay => {

                  proje.toplamCalisilanGun += parseInt(ay.days)

                })

                proje.sozlesmeAylari.forEach((ay, index) => {

                  var daysInMonth = this.tarihler.find(e => e.monthIndex == ay.monthIndex)
                  ay.dayPercentage = proje.sozlesmeAylari.length == 1 ? parseFloat(parseInt(ay.days) * 100 / daysInMonth.days).toFixed(1) : index > 0 && index < proje.sozlesmeAylari.length - 1 ? 100 : (parseFloat(parseInt(ay.days) * 100 / daysInMonth.days) + 3).toFixed(1) + 5

                })
                proje.bitti = checkDateRange(proje.baslamaTarihi, proje.tamamlanmaTarihi)


                proje.sozlesmeAylari.forEach(ay => {
                  // Ay nesnesinin tüm özelliklerini tarihler2 dizisine ekliyoruz
                  if (!this.tarihler2.some(item => item.month === ay.month && item.year === ay.year)) {
                    this.tarihler2.push(ay);
                  }
                });

                // tarihler2 dizisini yazdırarak sonuçları kontrol edebiliriz

              })
            })
            this.tarihler2.sort((a, b) => {
              if (a.year !== b.year) {
                return a.year - b.year; // İlk olarak yıllara göre sıralama
              } else {
                return a.monthIndex - b.monthIndex; // Yıllar aynıysa aylara göre sıralama
              }
            });
          }
          this.loading = false;
        }).catch((err) => {
          if (err.responseJSON) {
            let error = err.responseJSON;
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.sozlesmeProjeList = [];
            } else notification.Status("errorMsg", this, error.errMsg);
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    getSurecRapor() {
      const token = localStorage.getItem("userDataBGSurec");
      const formData = new FormData();
      formData.append("token", token);
      formData.append("serviceName", "sozlesme");
      formData.append("methodName", "surecRapor");

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        wsProvider.apiPath + "webservis.php"
      );
      xhr.responseType = "blob";

      xhr.onload = function () {
        const url = window.URL.createObjectURL(xhr.response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "surecrapor.pdf");
        document.body.appendChild(link);
        link.click();
      };

      xhr.onerror = function () {
        console.log("Error occured during the request");
      };

      xhr.send(formData);
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;

      // this.getProjeSayisi();
      // this.getProjeListesi();
    },
  },
};
function getDays(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür ve gün farkını hesapla
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, 'days');

  // Sonucu döndür
  return gunFarki;
}
function sozlesmeBittiMi(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  // Tarih1 tarih2'den sonra mı?
  return momentTarih1.isAfter(momentTarih2);
}

function projeBasladiMi(bugun, baslangicTarihi) {
  const momentBugun = moment(bugun)
  const momentBaslangic = moment(baslangicTarihi)

  return momentBugun.isAfter(momentBaslangic)
}
function checkDateRange(start, end) {
  const today = moment();
  if (today.isBefore(start, 'day')) {
    return 0;
  } else if (today.isBetween(start, end, 'day')) {
    return 1;
  } else {
    return 2;
  }
}
function getMonthsBetween(start, end) {
  moment.locale('tr');
  const results = [];

  for (let m = moment(start); m.isSameOrBefore(end, 'month'); m.add(1, 'months')) {
    const monthStart = moment(m).startOf('month');
    const monthEnd = moment(m).endOf('month');

    let daysInMonth = monthEnd.diff(monthStart, 'days') + 1;

    if (m.isSame(moment(start).startOf('month'), 'month')) {
      daysInMonth -= moment(start).date();
    }
    if (m.isSame(moment(end).endOf('month'), 'month')) {
      daysInMonth -= monthEnd.date() - moment(end).date();
    }

    results.push({
      year: monthStart.format('YYYY'), // Yıl bilgisini ekledik
      month: monthStart.format('MMMM'),
      monthIndex: parseInt(monthStart.month()) + 1,
      days: daysInMonth
    });
  }

  return results;
}
</script>


<style lang="scss">
#surecrapor {
  .el-table__cell {
    .cell {
      padding: 0 !important;
    }
  }

  .el-progress-bar__innerText {
    color: white !important;
  }

  thead th {
    background: #c7e3f6 !important;
    color: #00385a;
  }
}

.projeRow:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}
</style>

<style>
.pdf-button {
  align-self: flex-end;
}
</style>